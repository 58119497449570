import axios from 'axios';
import config from '../config';
import { handlingSession, sessionID } from './../../utils/handlingSession'
import { userLogs } from './../logs/userLogs'

export const consentResponse = async (formData) => {
    console.log(formData, "formData");

    let url = config.hummBackendAppURL + 'application/update-timestamp-bio-consent';
    return axios.post(url, formData, { headers: sessionID('secured') }).then(consentResponse => {
        return consentResponse;
    })
        .catch(async error => {
            await userLogs(url, formData, error);
            if (error.response) {
                handlingSession(error.response.status);
            }
            return error.response
        })

};