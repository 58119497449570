import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const getBrokerUserDetails = async (formData) => {
    const url = config.hummBackendAppURL + 'user/by-application-id?applicationId=' + formData;
  
    try {
      const res = await axios.get(url, { headers: sessionID('secured') });
      return res;
    } catch (err) {
      await userLogs(url, formData, err);
      if (err.response) {
        handlingSession(err.response.status);
        return err.response;
      }
      throw err;
    }
  };