import axios from 'axios';
import config from '../config';
import {handlingSession,sessionID} from './../../utils/handlingSession'
import {userLogs} from './../logs/userLogs'

export const updatePrivacyPolicyStatus = async (formData) => {
    let url = config.hummBackendAppURL + 'broker-application/update-status-of-privacy-policy-notify';
    
    try {
        const response = await axios.post(url, formData, { headers: sessionID('secured') });
        return response;
    } catch (error) {
        await userLogs(url, formData, error);
        if (error.response) {
            handlingSession(error.response.status); 
        }
        return error.response; 
    }
};