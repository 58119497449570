import axios from 'axios';
import config from '../config';
import { handlingSession, sessionID } from './../../utils/handlingSession'
import { userLogs } from './../logs/userLogs'

export const getConsentStatus = async (formData) => {
    let url = config.hummBackendAppURL + 'application/get-bio-consent-status?applicationId=' + formData;

    try {
        const response = await axios.get(url, { headers: sessionID('secured') });

        return response;
    } catch (error) {
        await userLogs(url, formData, error);
        
        if (error.response) {
            handlingSession(error.response.status);
            return error.response;
        }

        throw error;
    }
};