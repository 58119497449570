

import axios from 'axios';
import config from '../config';
import { handlingSession, sessionID } from './../../utils/handlingSession'
import { userLogs } from './../logs/userLogs'


export const getStatus = async (formData) => {
  const url = config.hummBackendAppURL + 'mitek/get_status_result';

  try {
    const res = await axios.post(url, formData, { headers: sessionID('secured') });
    return res;
  } catch (err) {
    await userLogs(url, formData, err);
    if (err.response) {
      handlingSession(err.response.status);
      return err.response;
    }
    throw err;
  }
};

