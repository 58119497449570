
import React, { Component } from "react";
import { frontEndLogs } from '../../action/logs/frontEndLogs';
import { privacyPolicyStatus } from "../../action/application/getPrivacyPolicyStatus";
import { updatePrivacyPolicyStatus } from "../../action/application/updatedPrivacyPolicyStatus";


export class BrokerPrivacyPolicy extends Component {
    constructor(props) {
        console.log('child', props);
        super(props);
        this.state = {
            showPrivacyPolicyModal: false
        }
    }

    componentDidMount() {
        let broker = window.location.search.includes('broker');
        if (broker) {
            this.fetchPrivacyPolicyStatus();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.showPrivacyPolicyModal && !prevState.showPrivacyPolicyModal) {
            document.body.classList.add("no-scroll");
        } else if (!this.state.showPrivacyPolicyModal && prevState.showPrivacyPolicyModal) {
            document.body.classList.remove("no-scroll");
        }
    }
    
    componentWillUnmount() {
        document.body.classList.remove("no-scroll");
    }

    fetchPrivacyPolicyStatus = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const applicationId = urlParams.get('applicationID') || await this.getApplicationIDInstorage();
        this.setState({ loading: true });
        const response = await privacyPolicyStatus(applicationId);

        if (response.data.statusCode === 200) {
            frontEndLogs('Privacy Policy Popup Display', false, 200, 'fetchPrivacyPolicyStatus', response);
            this.setState({
                showPrivacyPolicyModal: !response.data.data.isPopupShown
            });
        }
        this.setState({ loading: false });
    };


    updatePrivacyPolicyStatus = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const applicationId = urlParams.get('applicationID') || await this.getApplicationIDInstorage();
        let requestConsents = {
            "applicationId": applicationId,
            "isContinue": true
        }
        this.setState({ loading: true });
        const response = await updatePrivacyPolicyStatus(requestConsents);

        if (response.data.statusCode === 200) {
            frontEndLogs('Privacy Policy Status Updated on clicking Continue Button ', false, 200, 'updatePrivacyPolicyStatus', response);
            this.setState({
                showPrivacyPolicyModal: false,
            });
        }
        this.setState({ loading: false });
    }

    render() {
        return (
            this.state.showPrivacyPolicyModal && (
                <>
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1040, pointerEvents: 'none' }} />
                        <div className={`modal fade model-error ${this.state.showPrivacyPolicyModal ? 'show' : ''}`}
                            id="privacyPolicyConsentWebsiteModel"
                            role="dialog"
                            tabIndex="-1"
                            data-keyboard="false"
                            aria-labelledby="privacyPolicyConsentWebsitePopupLabel"
                            aria-hidden="true"
                            data-backdrop="static"
                            style={{
                                display: this.state.showPrivacyPolicyModal ? 'block' : 'none',
                                zIndex: 1050,
                                pointerEvents: 'auto'
                            }}>
                            <div role="document" className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <h5 className="modal-title" id="privacyPolicyConsentWebsiteModel">
                                            We value your privacy
                                        </h5>
                                        <p className="text-center mb-0 mt-md-4" style={{ fontSize: '19px' }}>
                                            Your privacy is important to us. Please take a moment to read our <a href="https://www.shophumm.com/uk/privacy-policy/" target='_blank' rel="noopener noreferrer"
                                                className='apply-important-info-link' id="mitek-privacy-policy-link" style={{ fontSize: '17px', borderBottom: 'none' }}>
                                                Privacy Policy</a> explaining how we collect, use, share and keep your personal data safe when you use our services.
                                        </p>
                                    </div>
                                    <div className="modal-footer pb-0 pl-0 m-4">
                                        <button type="button" id='moible-redirect-screen'
                                            className="btn btn-primary"
                                            style={{ fontSize: '27px' }}
                                            onClick={() => this.updatePrivacyPolicyStatus()}
                                        >
                                            <span className='privacy-policy-lock-img'><img src="images/material-symbols-light_lock-sharp.svg" alt="" style={{ height: '1.3rem' }} /></span>
                                            <span className='privacy-policy--continue-btn'>Continue</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </>
            ))
    }
}